<template>
  <div id="thanks-page">
    <div class="content">
      <transition name="fade" mode="out-in">
        <div class="container">
          <h2 class="thanks-sentence">
            THANK YOU!
          </h2>
          <img class="green-check" src="../../../assets/green-check.png"/>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    const navigate = this.$router;
    setTimeout(() => {
      navigate.push({
        name: 'billing',
      });
    }, 2000);
  },
};
</script>


<style lang="scss" scoped>

#thanks-page {
  overflow: hidden;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .input-container {
      margin-bottom: 25px;
      input {
        font-size: 18px;
        padding: 10px;
        background: transparent;
        border: none;
        border-bottom: 1px solid #aaa;
        &:focus {
          outline: 0;
          border: none;
          border-bottom: 1px solid #C44B57;
        }
      }
    }
  }

  .thanks-sentence {
    color: #a5a5a5;
    padding-bottom: 2px;
    border-bottom: 2px solid #C44B57;
  }
  .green-check {
    width: 150px;
    height: 150px;
  }
}
</style>
